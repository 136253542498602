import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const Appian = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Appian"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">TEQT’ s Appian BPM Practice has unmatched certification levels in the industry, thereby providing highly experienced teams on customer engagements. With 500+ Appian professionals and 350+ certified professionals on staff, we are ready to help you be successful in your next Appian Digital Transformation project. Businesses can adopt Appian to build enterprise apps and workflows rapidly to automate their manual processes.</p>
                        <p className="mt-2">TEQT offers a wide range of Appian low-code automation implementation services. Being an Appian Premier Partner, Appian Bronze Financial Services Accredited and Appian Bronze Insurance Services Accredited partner, we have built impactful and award-winning business productivity solutions on the Appian platform for our customers. Our newest service offering One Cloud Service for Appian, helps business assess their readiness to migrate to their preferred Appian Cloud offering. Our Appian solutions like Insurance Underwriting Automation, Insurance Underwriter Framework, Cloud Log Analyzer, Intelligent Document Processing, DevOps Framework, and our Appian Intelligent Decision Making, have been delivering great business results to our customers. Our deep expertise in Digital Process Automation technologies such as the low-code/no-code platforms, coupled with our strength in the domain empowers organizations to accelerate growth, transform consumer experience, and create lasting value.</p>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AppianPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

Appian.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Appian;
